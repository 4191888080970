// extracted by mini-css-extract-plugin
export var heroBackground = "index-module--heroBackground--UpJRD";
export var heroImageClass = "index-module--heroImageClass--2edFZ";
export var heroTitleCont = "index-module--heroTitleCont--2JJKi";
export var ourWorkCard = "index-module--ourWorkCard--2DCl8";
export var ourWorkContainer = "index-module--ourWorkContainer--105WV";
export var storyCard = "index-module--storyCard--3MgK8";
export var nameTag = "index-module--nameTag--vyk0V";
export var ourWorkText = "index-module--ourWorkText--rYGXM";
export var svg1 = "index-module--svg1--XTQit";
export var svg2 = "index-module--svg2--2k_F9";
export var researchImage = "index-module--researchImage--_W5tE";
export var educationImage = "index-module--educationImage--7kXJk";
export var researchText = "index-module--researchText--ThnSo";
export var titleRow = "index-module--titleRow--2H2xN";