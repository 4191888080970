import * as React from "react"
import { Link, graphql } from "gatsby"
import { StaticImage, getImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"
import { Container, Row, Col, Button } from "react-bootstrap"
import {
  heroBackground,
  heroImageClass,
  heroTitleCont,
  ourWorkCard,
  storyCard,
  nameTag,
  ourWorkText,
  titleRow,
  ourWorkContainer,
  svg1,
  svg2,
  researchImage,
  researchText,
  educationImage
} from "../styles/index.module.scss"
import { BgImage } from "gbimage-bridge"

import judy from '../images/judy-lewis.png'
import lynne from '../images/lynne-steblecki.png'
import simon from '../images/simon-weston.png'

const IndexPage = (pageQuery) => {

  const heroImage = getImage(pageQuery.data.heroImage.childImageSharp.gatsbyImageData)

  return (
    <Layout>
      <Seo title="Home" />
      <section>
        <div className={heroBackground}>
          {/* <div className={heroImageClass}>
          </div> */}

          <BgImage image={heroImage} className={heroImageClass}>
            <div></div>
          </BgImage>


          <div className={heroTitleCont}>
            <Container>
              <Row className={titleRow}>
                <Col xs={12} md={8} lg={7}>
                  <h1>
                    The Charles Wolfson Centre for Reconstructive Surgery
                </h1>
                  <p>
                    We bring together scientists and clinicians to accelerate the translation of scientific breakthroughs into innovative treatments for patients.
                </p>
                  <Link to="/about/">
                    <Button className="white-button rounded-button">Learn More</Button>
                  </Link>
                </Col>
              </Row>

            </Container>
          </div>
        </div>
      </section>

      <section style={{ background: 'white' }}>
        <svg width="100%" height="150" viewBox="0 0 500 120" preserveAspectRatio="none" className={`${svg1} background-svg`} style={{ marginTop: '-7rem' }}>
          <path d="M0,120 L0,80 Q250,0 500,80 L500,120 Z" fill="white" />
        </svg>
        <div className={ourWorkContainer}>
          <Container fluid={'md'}>
            <Row className="align-items-center">
              <Col md={12} lg={4}>
                <div className={ourWorkText}>
                  <h2>
                    Our Work
            </h2>

                  <div className="title-highlight"></div>
                  <p className="emphasis-text">
                    Our work focusses on three key areas; Research, Education, and of course, our patients.
            </p>
                </div>
              </Col>
              <Col sm={12} lg={8}>
                <Row className={titleRow}>
                  <Col xs={'auto'} sm={4}>
                    <div className={`${ourWorkCard} card`}>
                      <StaticImage
                        src="../images/research.svg"
                        width={80}
                        quality={80}
                        formats={["AUTO", "WEBP"]}
                        alt="Charles Wolfson Centre"
                      />
                      <h4 style={{ marginTop: '1rem' }}>Research</h4>
                      <Link to="/research/">
                        <Button className="rounded-button border-button clear-button">Learn More</Button>
                      </Link>
                    </div>
                  </Col>
                  <Col xs={'auto'} sm={4}>
                    <div className={`${ourWorkCard} card`}>
                      <StaticImage
                        src="../images/education.svg"
                        width={80}
                        quality={80}
                        formats={["AUTO", "WEBP"]}
                        alt="Charles Wolfson Centre"
                      />
                      <h4 style={{ marginTop: '1rem' }}>Education</h4>
                      <Link to="/education/">
                        <Button className="rounded-button border-button clear-button">Learn More</Button>
                      </Link>
                    </div>
                  </Col>
                  <Col xs={'auto'} sm={4}>
                    <div className={`${ourWorkCard} card`}>
                      <StaticImage
                        src="../images/user.svg"
                        width={80}
                        quality={80}
                        formats={["AUTO", "WEBP"]}
                        alt="Charles Wolfson Centre"
                      />
                      <h4 style={{ marginTop: '1rem' }}>Patients</h4>
                      <Link>
                        <Button className="rounded-button border-button clear-button">Learn More</Button>
                      </Link>

                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </div>

        <svg width="100%" viewBox="0 0 500 120" preserveAspectRatio="none" className={`${svg2} background-svg`} style={{ background: '#FAFAFA' }}>
          <path d="M0,0 L0,40 Q250,120 500,40 L500,0 Z" fill="white" />
        </svg>

      </section>

      <section>
        <Container>
          <Row className="align-items-center">
            <Col md={7}>
              <StaticImage
                src="../images/research-scene.png"
                formats={["AUTO", "WEBP"]}
                className={researchImage}
              />
            </Col>
            <Col md={5}>
              <div className={researchText}>
                <h2>
                  Research
            </h2>
                <div className="title-highlight" style={{ width: '30%' }}></div>
                <p>
                  Our work revolves around the priorities of our patients, bringing together scientists, academic clinicians, clinical trials specialists and nurses to translate new research into novel treatments.
            </p>
                <Link to="/research/">
                  <Button className="rounded-button border-button clear-button">Learn More</Button>
                </Link>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section>
        <svg width="100%" height="150" viewBox="0 0 500 120" preserveAspectRatio="none" className="background-svg">
          <path d="M0,120 L0,80 Q250,0 500,80 L500,120 Z" fill="white" />
        </svg>
        <div style={{ background: 'white', paddingBottom: '2rem' }}>
          <Container>
            <Row className="align-items-center">
              <Col xs={{order: 2, span: 12}} sm={{span: 12, order: 2}} md={{span:5, order: 1}}>
                <div className={researchText}>
                <h2>
                  Education
            </h2>
                <div className="title-highlight" style={{ width: '30%' }}></div>
                <p className="emphasis-text">
                  A key pillar of the Charles Wolfson Centre is to train the next generation of world class plastic and reconstructive surgeons of tomorrow.
            </p>
                <Link to="/education/">
                  <Button className="rounded-button border-button clear-button">Learn More</Button>
                </Link>
                </div>
              </Col>
              <Col xs={{order: 1, span: 12}} sm={{span: 12, order: 1}} md={{span:7, order: 2}} className="justify-content-end">
                <StaticImage
                  src="../images/education-scene.png"
                  formats={["AUTO", "WEBP"]}
                  className={educationImage}
                />
              </Col>
            </Row>
          </Container>
        </div>
        {/* <svg width="100%" height="150" viewBox="0 0 500 120" preserveAspectRatio="none" className="background-svg" style={{ background: '#FAFAFA' }}>
          <path d="M0,0 L0,40 Q250,120 500,40 L500,0 Z" fill="white" />
        </svg> */}
      </section>

      {/* <section>
        <Container>
          <Row className="justify-content-center">
            <Col md={'auto'}>
              <h2 className="text-center">Patient Stories</h2>
              <div className="title-highlight" style={{ width: '80%', margin: 'auto' }}></div>
            </Col>
          </Row>
          <Row style={{ marginTop: '3rem' }}>
            <Col>
              <div className={`${storyCard} card`} style={{ backgroundImage: `url(${judy})` }}>
                <div className={nameTag}>
                  Judy Lewis
              </div>
              </div>
            </Col>
            <Col>
              <div className={`${storyCard} card`} style={{ backgroundImage: `url(${lynne})` }}>
                <div className={nameTag}>
                  Lynne Steblecki
              </div>
              </div>
            </Col>
            <Col>
              <div className={`${storyCard} card`} style={{ backgroundImage: `url(${simon})` }}>
                <div className={nameTag}>
                  Simon Weston
              </div>
              </div>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col sm={'auto'}>
              <div style={{
                margin: '2rem 0'
              }}>
                <Link to="/stories/">
                  <Button className="rounded-button border-button clear-button">See More Stories</Button>
                </Link>
              </div>
            </Col>
          </Row>
        </Container>
      </section> */}
    </Layout>
  )
}

export const pageQuery = graphql`
  {
  heroImage: file(relativePath: {eq: "hero-image.jpg"}) {
    childImageSharp {
			gatsbyImageData(
        formats: [AUTO, WEBP]
        placeholder: BLURRED
      )
    }
  }
}
`



export default IndexPage